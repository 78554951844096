import { Typography, LinkHandler, Button } from '@/components/atoms';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';

interface ITitlePersonalized {
  title: string;
  url: string;
}

const Container = styled.div`
  margin-bottom: clamp(1.5rem, 1.2451rem + 1.0458vw, 2.5rem);
`;

const CustomTypo = styled(Typography)`
  margin-bottom: 16px;
`;

const TitlePersonalized: React.FC<ITitlePersonalized> = ({ title, url }) => {
  const { t } = useTranslation(['account']);

  return (
    <Container>
      <Typography datatype="heading_3">{title}</Typography>
      <CustomTypo datatype="body_1">
        {t('account:categoriesForm.based-preferences')}
      </CustomTypo>
      <LinkHandler href={url} passHref shallow={false}>
        <Button variant="text" size="medium">
          {t('account:categoriesForm.view-all')} {title.toUpperCase()}
        </Button>
      </LinkHandler>
    </Container>
  );
};

export default TitlePersonalized;
