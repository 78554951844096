import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CircularProgress, Typography } from '@/components/atoms';
import { ProductsSlider } from '@/components/molecules';
import { getPersonalizedProducts } from '@/lib/api.product';
import { useSelector } from 'react-redux';
import { Product } from '@/lib/models/types/product';
import { RootState } from '@/store/store';
import { PERSONALIZED_CAROUSELS } from '@/lib/constants';
import { isOk } from '@/utils/typings/result';
import { capitalize, pluralizeGender } from '@/utils/index';
import TitlePersonalized from './TitlePersonalized';
import { IProductCarouselCmp } from './IProductCarouselCmp';
import { useRouter } from 'next/router';
import { ProductCardCustomization } from '@/types/products';
import { getProductsByHandles } from '@/lib/models/product';

const Container = styled.div``;

const PersonalizedContainer = styled.div`
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 16px;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 180px;
`;

interface IRegistrationPref {
  gender: { gender: string; gender_heading: string };
  categories: {
    category: string;
    category_path: string;
    price_range: number[] | null;
  }[];
  styles: string[];
}

const ProductCarousel: React.FC<IProductCarouselCmp> = props => {
  const {
    type,
    template,
    showColorSwatch,
    showProductInfo,
    showVendor,
    openNewTab,
    limit,
    heading,
  } = props.fields;

  const { id, products: productsServer } = props;

  const router = useRouter();

  const [carouselId, setCarouselId] = useState<string | undefined>();
  const [personalizedCarousels, setPersonalizedCarousels] = useState<
    ({ data: Product[]; url: string; title: string } | null)[]
  >([]);
  const [loadingPersonalizedCarousels, setLoadingPersonalizedCarousels] =
    useState(false);

  const [wishlistCarousels, setWishlistCarousels] = useState<{
    data: Product[];
    url?: string;
    title?: string;
  } | null>(null);
  const [loadingWishlistCarousel, setLoadingWishlistCarousel] = useState(false);

  const [loadingCartCarousel, setLoadingCartCarousel] = useState(false);

  const [cartCarousels, setCartCarousels] = useState<{
    data: Product[];
    url?: string;
    title?: string;
  } | null>(null);

  const currency = useSelector(
    (state: RootState) => state.currencyReducer.currency
  );
  const customer = useSelector(
    (state: RootState) => state.userReducer.customer
  );
  const segmentPrice = useSelector(
    (state: RootState) => state.userReducer?.customer?.segmentPrice?.value
  );

  const wishlistData = useSelector(
    (state: RootState) => state.wishlistReducer.wishlist
  );

  const cartHandles = useSelector(
    (state: RootState) => state.cartReducer.cartHandles
  );

  const customization: ProductCardCustomization = {
    showColorVariants: showColorSwatch,
    showProductInfo,
    showVendor,
    openNewTab,
  };

  const mode =
    type === 'personalized' || type === 'wishlist' || type === 'cart'
      ? 'client'
      : 'server';

  const setCarousel = async (type: string) => {
    if (type === 'personalized' && customer?.registrationPreferences) {
      const registrationPreferences: IRegistrationPref = JSON.parse(
        customer.registrationPreferences.value
      );

      const { gender, categories } = registrationPreferences;
      setLoadingPersonalizedCarousels(true);
      const carousels = await Promise.all(
        categories.slice(0, PERSONALIZED_CAROUSELS).flatMap(async category => {
          const personlizedCarousel = category.category_path
            ? await getPersonalizedProducts(
                limit || 16,
                gender.gender,
                category.category_path,
                segmentPrice
              )
            : null;
          if (personlizedCarousel && isOk(personlizedCarousel)) {
            return {
              data: personlizedCarousel?.result?.items,
              url: `collections/${pluralizeGender(gender.gender)}/${
                category.category_path
              }`,
              title: capitalize(category.category),
            };
          } else {
            return null;
          }
        })
      );

      if (carousels) {
        setPersonalizedCarousels(carousels);
        setLoadingPersonalizedCarousels(false);
      }
    } else if (type === 'wishlist') {
      setLoadingWishlistCarousel(true);
      if (wishlistData && wishlistData.length > 0) {
        let payload: string[] = [];

        wishlistData.forEach((item, index) => {
          if (index < (limit || 16)) {
            payload.push(item.du);
          }
        });

        if (payload.length > 0) {
          const result = await getProductsByHandles(payload);
          if (isOk(result) && result.result.length > 0) {
            setWishlistCarousels({ ...wishlistCarousels, data: result.result });
          }
        }
      }
      setLoadingWishlistCarousel(false);
    } else if (type === 'cart') {
      setLoadingCartCarousel(true);
      if (cartHandles && cartHandles.length > 0) {
        let payload: string[] = [];

        cartHandles.forEach((item, index) => {
          if (index < (limit || 16)) {
            payload.push(item);
          }
        });

        const result = await getProductsByHandles(payload);
        if (isOk(result) && result.result.length > 0) {
          setCartCarousels({ ...cartCarousels, data: result.result });
        }
      }
      setLoadingCartCarousel(false);
    }
  };

  useEffect(() => {
    setCarouselId(id);
  }, [router.asPath]);

  useEffect(() => {
    if (carouselId && type) {
      setCarousel(type);
    }
  }, [carouselId]);

  useEffect(() => {
    if (customer && customer.registrationPreferences && type) {
      setCarousel(type);
    }
  }, [customer]);

  useEffect(() => {
    if (wishlistData && wishlistData.length > 0 && type) {
      setCarousel(type);
    }
  }, [wishlistData]);

  useEffect(() => {
    if (cartHandles && cartHandles.length > 0 && type) {
      setCarousel(type);
    }
  }, [cartHandles]);

  let content = null;
  let contentClient = null;

  if (mode === 'client') {
    if (type === 'personalized') {
      contentClient = loadingPersonalizedCarousels ? (
        <Loading>
          <CircularProgress />
        </Loading>
      ) : (
        personalizedCarousels.length > 0 &&
        personalizedCarousels.map((pc, index) => {
          return (
            pc && (
              <PersonalizedContainer key={index}>
                <TitleContainer>
                  <TitlePersonalized title={pc.title} url={pc.url} />
                </TitleContainer>
                <div>
                  {currency && type && (
                    <ProductsSlider
                      products={pc?.data || []}
                      template={template}
                      type={type}
                      customization={customization}
                      mode={mode}
                    />
                  )}
                </div>
              </PersonalizedContainer>
            )
          );
        })
      );
    } else if (type === 'wishlist') {
      contentClient = loadingWishlistCarousel ? (
        <Loading>
          <CircularProgress />
        </Loading>
      ) : wishlistCarousels ? (
        <div>
          {heading ? (
            <TitleContainer>
              <Typography datatype="heading_3">{heading}</Typography>
            </TitleContainer>
          ) : null}
          <ProductsSlider
            products={wishlistCarousels?.data || []}
            template={template}
            type={type}
            customization={customization}
            mode={mode}
          />
        </div>
      ) : null;
    } else if (type === 'cart') {
      contentClient = loadingCartCarousel ? (
        <Loading>
          <CircularProgress />
        </Loading>
      ) : cartCarousels ? (
        <div>
          {heading ? (
            <TitleContainer>
              <Typography datatype="heading_3">{heading}</Typography>
            </TitleContainer>
          ) : null}
          <ProductsSlider
            products={cartCarousels?.data || []}
            template={template}
            type={type}
            customization={customization}
            mode={mode}
          />
        </div>
      ) : null;
    }
  }

  content =
    mode === 'server' ? (
      <Container>
        <div>
          {type && (
            <ProductsSlider
              products={productsServer}
              template={template}
              type={type}
              customization={customization}
              mode={mode}
            />
          )}
        </div>
        {/* {type === 'collection' &&
          props.fields.collection &&
          props.fields.buttonCta && (
            <LinkContainer>
              <Button>
                <LinkHandler
                  href={
                    '/collections/' + getHandleReverse(props.fields.collection)
                  }
                  passHref
                  shallow={false}>
                  <a>{props.fields.buttonCta}</a>
                </LinkHandler>
              </Button>
            </LinkContainer>
          )} */}
      </Container>
    ) : (
      contentClient
    );

  return <>{content}</>;
};

export default ProductCarousel;
