import { CardSlider } from '@/components/molecules';
import { IProductsSlider } from '@/interfaces/index';
import React from 'react';
import styled from 'styled-components';
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs,
} from 'swiper';
import ProductCardV2 from '../ProductCard';

const Container = styled.div`
  display: grid;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, 45%);
  justify-content: center;
  max-width: 540px;
  margin: auto;
  ${props => props.theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(3, 30%);
    max-width: inherit;
  }
  ${props => props.theme.breakpoints.up('md')} {
    grid-template-columns: repeat(4, 22%);
    max-width: inherit;
  } ;
`;

const ProductContainer = styled.div`
  &:last-child {
    margin-bottom: 0px;
  }
  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: 0px;
  }
`;

SwiperCore.use([Navigation, Pagination, Autoplay, Thumbs, Scrollbar]);

const ProductsSlider: React.FC<IProductsSlider> = props => {
  const { template, products, type, customization, mode } = props;

  let content = null;

  switch (template) {
    case 'grid':
      content =
        mode === 'server' ? (
          <Container>
            {products &&
              products.length > 0 &&
              products.map(product => {
                return (
                  <ProductContainer key={product.id}>
                    <ProductCardV2
                      product={product}
                      template={template}
                      customization={customization}
                    />
                  </ProductContainer>
                );
              })}
          </Container>
        ) : (
          // questo ramo si verifica ancora? ProductCard vuole node obbligatorio ma non viene passato e pr.id non esiste
          <Container>
            {products &&
              products.length > 0 &&
              products.map(product => {
                return (
                  <ProductContainer key={product.id || product.id}>
                    <ProductCardV2
                      template={template}
                      product={product}
                      customization={customization}
                    />
                  </ProductContainer>
                );
              })}
          </Container>
        );
      break;
    case 'carousel':
    case 'carousel_small':
      content =
        products && products.length > 0 ? (
          <CardSlider
            cards={products}
            typeCard={'product'}
            withScrollBar={false}
            type={type}
            template={template}
            customization={customization}
            mode={mode}
          />
        ) : null;

      break;
  }

  return products && products.length > 0 ? content : null;
};

export default ProductsSlider;
